.react-datepicker__header {
  background: #82b378 !important;
  color: #ffffff !important;
}
.react-datepicker__header div {
  color: #ffffff !important;
}
.react-datepicker__header div:hover {
  color: #2e7423 !important;
}
.react-datepicker__month {
  background: #f1ece2 !important;
}
.react-datepicker__day--selected {
  background: #82b378 !important;
}
.react-datepicker__day--keyboard-selected {
  background: #82b378 !important;
}
.react-datepicker__day--selected:hover {
  background: #2e7423 !important;
}
.react-datepicker__day--keyboard-selected:hover {
  background: #2e7423 !important;
}

.date-picker-reports {
  z-index: 99999 !important;
}
