img {
  height: 100px;
}

h1 {
  color: #2e7423;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  list-style: none;
  cursor: pointer;
}

.pagination a {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #2e7423;
  color: #2e7423;
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  color: #fff;
  background: #2e7423;
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
  cursor: not-allowed;
}
