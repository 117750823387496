.control-arrow {
  background: white !important;
  opacity: 0.5 !important;
  width: 15% !important;
}

.carousel .control-prev.control-arrow:before {
  content: '';
  border: solid rgba(0, 0, 0, 0.63);
  border-width: 0 5px 5px 0;
  display: inline-block;
  padding: 8px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(135deg);
}

.carousel .control-next.control-arrow:before {
  content: '';
  border: solid rgba(0, 0, 0, 0.63);
  border-width: 0 5px 5px 0;
  display: inline-block;
  padding: 8px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
